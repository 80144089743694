<template>
  <div class="welcome d-flex align-center justify-center">
    <workspace-form @success="$router.replace(`/${$event}`)" />
  </div>
</template>

<script>
import WorkspaceForm from '@/views/workspace/WorkspaceForm.vue'

export default {
  components: {
    WorkspaceForm,
  },
}
</script>

<style lang="scss">
  .welcome {
    height: 100%;
    width: 100%;
    background: url('https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80');
    background-size: cover;
    background-repeat: no-repeat;

    .welcome-lottie {
      max-width: 320px;
      padding: 0;
      margin-left: -20px !important;
      margin-bottom: -40px !important;
    }
  }
</style>
